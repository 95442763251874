<template>
  <div>
    <div class="scrollbar_wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </div>
    <toggle-sideBar :is-active="sidebar.opened" @toggleClick="toggleSideBar" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
import ToggleSideBar from './ToggleSideBar.vue';
import variables from '@/assets/styles/variables.module.scss';

export default {
  name: 'TSSidebar',
  components: { SidebarItem, ToggleSideBar },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      // console.log(this.$router.options.routes);
      const router_list = this.$router.options.routes;
      const show_route = this.filterRouteByUserType(router_list);
      return show_route;
    },
    variables() {
      return variables;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    filterRouteByUserType(router_list) {
      const user_type = localStorage.getItem('admin_user_type');
      let show_route = [];
      if (user_type === '1') {
        show_route = router_list.filter(route => {
          const showMenu = ['console', 'license'];
          // 隐藏的基础路由不改
          if (route.hidden) return true;
          // 过滤出来系统管理员要显示的模块
          if (showMenu.includes(route.name)) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        show_route = router_list.filter(route => {
          const hiddenMenu = ['console', 'license'];
          // 存在系统管理员模块过滤掉
          if (hiddenMenu.includes(route.name)) {
            return false;
          } else {
            return true;
          }
        });
      }

      return show_route;
    }
  }
};
</script>
<style lang="scss"></style>
