import Layout from '@/layout/Index';

const SemanticClassifier = () =>
  import(
    /* webpackChunkName: "quality-engine" */ '@/views/quality-engine/semantic-classifier'
  );
const RegularExpression = () =>
  import(
    /* webpackChunkName: "quality-engine" */ '@/views/quality-engine/regular-expression'
  );
const Keyword = () =>
  import(
    /* webpackChunkName: "quality-engine" */ '@/views/quality-engine/keyword'
  );

export default {
  path: '/quality-engine',
  name: 'quality-engine',
  meta: { title: '质检引擎', icon: 'quality-engine' },
  component: Layout,
  redirect: '/quality-engine/semantic-classifier',
  children: [
    {
      path: 'semantic-classifier',
      name: 'semantic-classifier',
      meta: { title: '语义分类器' },
      component: SemanticClassifier
    },
    {
      path: 'regular-expression',
      name: 'regular-expression',
      meta: { title: '正则表达式' },
      component: RegularExpression
    },
    {
      path: 'keyword',
      name: 'keyword',
      meta: { title: '关键词' },
      component: Keyword
    }
  ]
};
