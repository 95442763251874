import router from './index';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import store from '@/store';

// 定义白名单
let whiteList = ['/about', '/login'];

router.beforeEach(async (to, from, next) => {
  const token = Cookies.get('teamsun_token');
  // 看token是否过期
  if (!token && to.path !== '/login') {
    store.commit('app/clearToken');
  }
  if (token) {
    // 已经有token了还是登录页直接去首页
    if (to.path === '/login') {
      handleRedirectWithToken(next, token);
    } else {
      //判断是不是已经请求拿了路由规则了
      // if (store.state.asyncRoute.length == 0) {
      //   const _asyncRoutes = await store.dispatch('getRouter');
      //   _asyncRoutes.forEach(item => {
      //     // console.log(item);
      //     router.addRoute(item);
      //   });
      //   // 路由创建好了，继续跳转去你要去的页面
      //   next(to.path);
      // } else {
      // 已经有了权限动态路由判断是否有权限访问当前路由
      if (to.matched.length !== 0) {
        // 有匹配项，继续跳转
        // 目前没有动态路由。单独user_type判断/
        if (to.path === '/' || to.path === '/portal') {
          const user_type = store.getters.user_type;
          if (user_type === 1) {
            if (from.path !== '/' && from.path !== '/404') {
              // 无权限跳回来的路由
              next(from.path);
            } else {
              next('/console/enterprise-list');
            }
          }
        }
        next();
      } else {
        // alert('无页面权限');
        // 无权限跳回来的路由
        next(from.path);
      }
      // }
    }
  } else {
    // 如果没token,看在不在路由白名单
    if (whiteList.indexOf(to.path) != -1) {
      next();
    } else {
      // 不在白名单直接去登录页
      next('/login');
    }
  }
});

// // 当从别的平台没有token跳过来
// // 当前中台有token，判断url后面参数是否有重定向参数，直接跳转重定向携带token
// 提取并处理重定向逻辑的函数
function handleRedirectWithToken(next, token) {
  // 获取URL查询字符串部分
  const queryStringPart = window.location.href.split('?')[1];

  // 如果没有查询字符串，直接返回
  if (!queryStringPart) {
    const user_type = store.getters.user_type;
    if (user_type === 1) {
      next('/console/enterprise-list');
    } else {
      next('/');
    }
    return;
  }

  // 解析查询参数
  const parsed = queryString.parse(queryStringPart);

  // 提取 app 和 redirect 参数
  const { app, redirect } = parsed;

  // 如果 app 存在且 redirect 存在，则进行重定向
  if (app && redirect) {
    if (redirect.includes('localhost')) {
      // 使用 window.location.replace 进行重定向，并附带 token
      window.location.replace(`${redirect}?token=${token}`);
    } else {
      window.location.replace(`${redirect}`);
    }
  }
}
