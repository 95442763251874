<template>
  <div v-if="!item.hidden">
    <template v-if="!item.children || !item.children.length">
      <link-to :to="resolvePath(item.path)">
        <el-menu-item
          :index="resolvePath(item.path)"
          class="submenu_title_no_dropdown"
        >
          <item
            :icon="item.meta.icon || (item.meta && item.meta.icon)"
            :title="item.meta.title"
          />
        </el-menu-item>
      </link-to>
    </template>
    <template
      v-else-if="
        item.children.filter(v => v.hidden).length === item.children.length
      "
    >
      <link-to :to="resolvePath(item.children[0].path)">
        <el-menu-item
          :index="resolvePath(item.children[0].path)"
          class="submenu_title_no_dropdown"
        >
          <item
            :icon="item.meta.icon || (item.meta && item.meta.icon)"
            :title="item.meta.title"
          />
        </el-menu-item>
      </link-to>
    </template>
    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path, true)"
      popper-append-to-body
    >
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title"
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :base-path="resolvePath(child.path, true)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import Item from './Item';
import LinkTo from './Link';

export default {
  name: 'SidebarItem',
  components: { Item, LinkTo },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  methods: {
    isExternal(path) {
      return /^(https?:|mailto:|tel:)/.test(path);
    },
    resolvePath(routePath, isMenu) {
      if (this.isExternal(routePath)) {
        return routePath;
      }
      if (this.isExternal(this.basePath)) {
        return this.basePath;
      }
      if (isMenu) {
        return this.basePath;
      } else {
        let str = this.basePath + '/' + routePath;
        str = str.replace('//', '/');
        return str;
      }
    }
  }
};
</script>
