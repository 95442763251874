const watermark = {};
const id = 'teamsun_water';

/**
 *
 * @param {要设置的水印的内容数组} strArr
 * @param {需要设置水印的容器} container
 */
const setWatermark = (strArr, container) => {
  if (container === null) {
    return;
  }

  // 查看页面上有没有，如果有则删除
  if (document.getElementById(id) !== null) {
    const childelement = document.getElementById(id);
    childelement.parentNode.removeChild(childelement);
  }

  const containerWidth = container.offsetWidth; // 获取父容器宽
  const containerHeight = container.offsetHeight; // 获取父容器高
  container.style.position = 'relative'; // 设置布局为相对布局

  // 创建canvas元素(先制作一块背景图)
  const can = document.createElement('canvas');
  can.width = 300; // 设置每一块的宽度
  can.height = 200; // 高度
  can.size = 20;
  const cans = can.getContext('2d'); // 获取canvas画布
  cans.rotate((-20 * Math.PI) / 180); // 逆时针旋转π/9  cans.font = '20px Vedana' // 设置字体
  cans.fillStyle = 'rgba(0, 0, 0, 0.08)'; // 设置字体的颜色
  cans.textAlign = 'left'; // 文本对齐方式

  cans.font = '300 14px PingFang SC'; // 水印字体样式
  cans.textBaseline = 'Middle'; // 文本基线
  // 单行文字
  //   cans.fillText(str, 0, can.height / 2); // 绘制文字
  // 多行 渲染水印
  strArr.forEach((text, index) => {
    cans.fillText(text, 0, 100 + 30 * index); // 纵向拉开30的间距
  });
  // 创建一个div元素
  const div = document.createElement('div');
  div.id = id; // 设置id
  div.style.pointerEvents = 'none'; // 取消所有事件
  div.style.top = '0px';
  div.style.left = '0px';
  div.style.position = 'absolute';
  div.style.zIndex = '100000';
  div.style.width = `${containerWidth}px`;
  div.style.height = `${containerHeight}px`;
  div.style.background = `url(${can.toDataURL('image/png')}) left top repeat`;
  container.appendChild(div); // 追加到页面
};

const clear = () => {
  const elementById = document.getElementById(id);
  elementById.style.background = '';
};

// 定义节流函数
function throttle(func, delay) {
  let timer = null;
  return function (...args) {
    if (!timer) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, delay);
    }
  };
}

// 该方法只允许调用一次
watermark.set = (str, container) => {
  setWatermark(str, container);
  // 暴力方案  可以采用
  //   setInterval(() => {
  //     if (document.getElementById(id) === null) {
  //       setWatermark(str, container);
  //     }
  //   }, 500);
  // 设置节流后的事件处理函数
  const throttledResizeHandler = throttle(() => {
    setWatermark(str, container);
  }, 1000); // 设置时间间隔为200毫秒（可以根据需要调整）
  // 监听页面大小的变化
  window.onresize = throttledResizeHandler;
};

watermark.clear = clear;

export default watermark;
