<template>
  <section class="ts-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'TsMain',
  computed: {
    key() {
      return this.$route.path;
    }
  }
};
</script>

<style scoped>
.ts-main {
  /* 50 = navbar margin-top = 20 margin-bottom = 20 */
  max-height: calc(100vh - 90px);
  margin: 20px;
  background-color: var(--color-bg-content);
  flex: 1;
  border-radius: 16px;
  position: relative;
  overflow: auto;
}
</style>
