import Cookies from 'js-cookie';
import { initRoutes, resetRouter } from '@/router';
import request from '@/utils/request';

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus')
      ? !!+Cookies.get('sidebarStatus')
      : true,
    withoutAnimation: false
  },
  asyncRoute: [],
  routes: [...initRoutes],
  token: Cookies.get('teamsun_token') || '',
  user_type: Number(localStorage.getItem('admin_user_type')) || 3
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = '';
    Cookies.remove('teamsun_token');
  },
  removeToken(state) {
    state.token = null; // 设置vuex中的token为null
    Cookies.remove('teamsun_token');
  },

  setUserType(state, user_type) {
    state.user_type = user_type || 3;
  },
  SET_ROUTES: (state, routes) => {
    state.asyncRoute = routes;
    // state.routes = initRoutes.concat(routes);
  },
  RESET_ROUTES: state => {
    state.asyncRoute = [];
    state.routes = [];
  },
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  }
};

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  setToken({ commit }, token) {
    return new Promise(resolve => {
      commit('setToken', token);
      resolve();
    });
  },
  getRouter({ commit }) {
    function parseRouter(routeArr) {
      let _newArr = [];
      routeArr.forEach(item => {
        let _newItem = Object.assign({}, item);
        let _str = item.component;
        //" /page1.vue"=>import( "@/views/page1.vue")
        _newItem.component = () => {
          //return require([^@/views${_ str}^],resolve)
          return import(`@/views${_str}`);
        };
        _newArr.push(_newItem);
      });
      return _newArr;
    }

    return new Promise(resolve => {
      let _local = JSON.parse(localStorage.getItem('admin-menu'));
      if (_local) {
        let _newArr = parseRouter(_local);
        commit('SET_ROUTES', _newArr);
        resolve(_newArr);
      } else {
        request('/api/routes')
          .then(response => {
            let _newArr = parseRouter(response.data.routes);
            console.log(JSON.stringify(_newArr));
            localStorage.setItem(
              'admin-menu',
              JSON.stringify(response.data.routes)
            );
            commit('SET_ROUTES', _newArr);
            resolve(_newArr);
          })
          .catch(error => {
            console.error('Failed to fetch mocked users:', error);
          });
      }
    });
  },
  resetRouter({ commit }) {
    // 清空目前路由里的已添加动态数据
    resetRouter();
    commit('RESET_ROUTES');
  },
  // 登出action
  logout({ commit }) {
    // 删除token
    commit('removeToken');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
