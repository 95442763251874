<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const { icon, title } = context.props;
    const vnodes = [];

    if (icon) {
      if (icon.includes('el-icon')) {
        vnodes.push(<i class={[icon, 'sub-el-icon']} />);
      } else {
        vnodes.push(<ts-icon icon-name={icon} />);
      }
    }

    if (title) {
      vnodes.push(
        <span slot="title" class="title">
          {title}
        </span>
      );
    }
    return vnodes;
  }
};
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 18px;
  height: 18px;
}

.el-submenu [class^='el-icon-'] {
  width: 18px;
}
</style>
