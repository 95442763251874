const str = location.hostname;
let domain = '';
let tsMainDomain = ''; // 中台服务
let axiosBaseUrl = '';
if (str) {
  switch (str) {
    // 开发环境
    case '172.18.117.71':
      tsMainDomain = '//172.18.117.71:8080';
      axiosBaseUrl = '/';
      break;
    // 测试环境
    case '172.18.117.166':
      tsMainDomain = '//172.18.117.166';
      axiosBaseUrl = '/';
      break;
    case 'ipc.teamsun.com.cn': // 目前绑定172.18.117.82
      tsMainDomain = '//ipc.teamsun.com.cn/';
      axiosBaseUrl = '/';
      break;
    default:
      // localhost
      domain = '';
      tsMainDomain = '';
      break;
  }
}

export { domain, tsMainDomain, axiosBaseUrl };
