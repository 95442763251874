export default {
  general: {
    cancel: '取 消',
    confirm: '确 定'
  },
  login: {
    login: '登录',
    username: '账号',
    password: '密码',
    account_place: '输入帐号',
    password_place: '输入密码',
    contact_sm: '无法登录？请联络管理人员',
    tips_input: '请输入帐密'
  },
  // 下面不知道
  route: {
    dashboard: '首页',
    manage: '后台管理',
    users: '用户管理',
    menus: '菜单管理',
    logs: '日志管理',
    example: '示例',
    table: '数据列表',
    // permissions: '权限管理',
    // employees: '员工',
    // employeesList: '员工管理',
    // employeesInfo: '个人信息',
    postInfo: '岗位信息',
    manageSelf: '经理自助',
    setting: '设置',
    report: '报表',
    employeesAdd: '添加员工',
    EditiNfo: '编辑信息',
    print: '打印页面',
    form: '表单页',
    basicForm: '基础表单',
    stepForm: '分步表单',
    advancedList: '高级表单',
    step: '步骤',
    details: '详情页',
    BasicsDetails: '基础详情页',
    seniorDetails: '高级详情页',
    import: '导入',
    // 注册
    register: '人资-注册',
    login: '人资-登录',
    // 审批
    approvals: '审批', // 审批
    salaryApproval: '工资审核',
    enterApproval: '入职审核',
    leaveApproval: '申请请假',
    quitApproval: '申请离职',
    overtimeApproval: '加班申请',
    securitySetting: '审批设置',
    // 员工
    employees: '员工',
    employeesList: '员工列表',
    employeesInfo: '个人信息',
    employeesAdjust: '调岗',
    employeesLeave: '离职',
    employeesPrint: '打印',

    // 工资
    salarys: '工资',
    salarysList: '工资列表',
    salarysSetting: '工资设置',
    salarysDetails: '工资详情',
    salarysHistorical: '历史归档',
    salarysMonthStatement: '月报表',
    // 社保
    social_securitys: '社保',
    socialSecuritys: '社保管理',
    socialDetail: '详情',
    socialHistorical: '历史归档',
    socialMonthStatement: '当月报表',
    // 组织架构
    departments: '组织架构',
    'departments-import': '引入',
    // 公司
    settings: '公司设置',
    // 考勤
    attendances: '考勤',
    usersApprovals: '用户审批',
    // saas企业
    'saas-clients': '企业',
    'saas-clients-details': '企业详情',
    // 权限
    permissions: '权限管理' // 权限管理
  },
  navbar: {
    search: '站内搜索',
    logOut: '退出登录',
    dashboard: '首页',
    github: '项目地址',
    screenfull: '全屏',
    theme: '换肤',
    lang: '多语言',
    error: '错误日志'
  },
  tagsView: {
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有',
    refresh: '刷新'
  },
  table: {
    title: '请输入用户',
    search: '搜索',
    add: '添加',
    addUser: '新增用户',
    id: '序号',

    email: '邮箱',
    phone: '手机',
    name: '姓名',
    entryTime: '入职时间',
    hireForm: '聘用形式',
    jobNumber: '工号',
    department: '部门',
    managementForm: '管理形式',
    city: '工作城市',
    turnPositiveTime: '转正时间',

    permissionNew: '新增权限组',
    permissionUser: '权限组名称',
    imdsAi: '高级接口授权',
    avatar: '头像',
    introduction: '介绍',
    paddword: '密码',
    powerCode: '权限代码',
    powerDistriB: '权限分配',
    powerTitle: '权限标题',
    powerNav: '主导航',
    actions: '操作',
    edit: '编辑',
    delete: '删除',
    cancel: '取 消',
    confirm: '确 定',
    return: '返回',
    operationType: '操作类型',
    operationDate: '操作时间',
    date: '日期',
    submit: '提交',
    operator: '操作人',
    results: '执行结果',
    describe: '描述',
    save: '保存',
    signOut: '退出',
    reset: '重置',
    know: '我知道了',
    view: '查看'
  }
};
