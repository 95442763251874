import Vue from 'vue';
import axios from 'axios';
import { Message } from '@ts/element-ui';
import store from '@/store';
import router from '@/router';
import Cookies from 'js-cookie';
import { axiosBaseUrl } from '@/utils/domain';

const TimeOut = 5400; // 定义超时时间
const service = axios.create({
  // 设置请求的前缀路径
  baseURL: axiosBaseUrl
  // timeout: 10000 // 认为只要超过5秒钟不响应 就超时
});

// 请求拦截器
service.interceptors.request.use(
  async config => {
    // 请求接口  config是请求配置
    // 取token
    const token = Cookies.get('teamsun_token');

    if (token) {
      // 如果存在token
      config.headers.Authorization = `${token}`;
      config.headers.enterprise_id =
        localStorage.getItem('enterprise_id') || '';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  response => {
    // 成功执行
    const { message } = response.data;
    // 只拦截接口状态
    if (response.status === 200) {
      return response.data; // 返回接口数据
    } else {
      // 当接口失败的时候
      Message.error(message); // 提示消息
      return Promise.reject(new Error(message));
    }
  },
  async error => {
    // error 有response对象 config
    if (error.response && error.response.status === 401) {
      // 后端告诉前端token超时了
      Message.error('token失效,请重新登录'); // 提示消息
      await store.dispatch('app/logout'); // 调用登出action
      router.push('/login'); // 跳到登录页
      return;
    }
    // 失败
    // reject
    return Promise.reject(error); // 传入一个错误的对象  就认为promise执行链 进入了catch
  }
);

const methods = [
  'get',
  'delete',
  'head',
  'options',
  'post',
  'put',
  'patch',
  'read',
  'create',
  'update'
];
methods.map(method => {
  service[method] = function (url, params = {}, moreConfig = {}) {
    const config = {};
    if (method == 'get') {
      config.params = params;
    } else {
      config.data = params;
    }
    config.method = method;
    config.url = url;
    let headers = {
      'Content-Type': 'application/json'
    };
    config.headers = Object.assign(headers, moreConfig.headers);
    config.withCredentials = true;
    if (moreConfig.cancelToken) {
      config.cancelToken = moreConfig.cancelToken;
    }
    if (moreConfig.signal) {
      config.signal = moreConfig.signal;
    }
    if (moreConfig.transformRequest) {
      config.transformRequest = moreConfig.transformRequest;
    }
    if (moreConfig.responseType) {
      config.responseType = moreConfig.responseType;
    }
    if (moreConfig.onUploadProgress) {
      config.onUploadProgress = moreConfig.onUploadProgress;
    }
    return service.request(config);
  };
});

if (!Vue.prototype.$ajax) {
  Vue.prototype.$ajax = service;
}

export default service;

// 定义 get 方法
export const get = (url, params, headers) => {
  return service({ url, method: 'get', params, headers });
};
// export const get = (url, params, headers) => {
//   return service.get(url, { params, headers });
// };
// 定义 post 方法
export const post = (url, data, headers) => {
  return service({ url, method: 'post', data, headers });
};

// 定义 Delete 方法
export const Delete = (url, data, headers) => {
  return service({ url, method: 'delete', data, headers });
};
