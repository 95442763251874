import Layout from '@/layout/Index';

const EnterpriseList = () =>
  import(
    /* webpackChunkName: "console" */ '@/views/console/enterprise-list/EnterpriseList.vue'
  );
const EnterpriseDetail = () =>
  import(
    /* webpackChunkName: "console" */ '@/views/console/enterprise-list/EnterpriseDetail.vue'
  );
const PersonalConfig = () =>
  import(
    /* webpackChunkName: "console" */ '@/views/console/enterprise-list/PersonalConfig.vue'
  );
const SysAdminList = () =>
  import(
    /* webpackChunkName: "console" */ '@/views/console/sys-admin-list/SysAdminList.vue'
  );
const Redash = () =>
  import(/* webpackChunkName: "console" */ '@/views/console/redash/index.vue');

export default {
  path: '/console',
  name: 'console',
  meta: { title: '企业管理', icon: 'quality-engine' },
  component: Layout,
  redirect: '/console/enterprise-list',
  children: [
    {
      path: 'enterprise-list',
      name: 'enterprise-list',
      meta: { title: '企业列表' },
      component: EnterpriseList
    },
    {
      path: 'enterprise-detail',
      name: 'enterprise-detail',
      meta: {
        title: '企业详情设置',
        activeMenu: '/console/enterprise-list'
      },
      hidden: true,
      component: EnterpriseDetail
    },
    {
      path: 'enterprise-personal',
      name: 'enterprise-personal',
      meta: {
        title: '个性化',
        activeMenu: '/console/enterprise-list'
      },
      hidden: true,
      component: PersonalConfig
    },
    {
      path: 'sys-admin-list',
      name: 'sys-admin-list',
      meta: { title: '系统管理员' },
      component: SysAdminList
    }
    // {
    //   path: 'test',
    //   name: 'test',
    //   meta: { title: 'test' },
    //   component: Redash
    // }
  ]
};
