<template>
  <div id="page-header">
    <div class="header_left">
      <div class="app_logo">
        <img :src="logSrc" alt="logo" />
        <span v-if="show_admin_platform && hasPresonalLogo">管理控制台</span>
      </div>
    </div>
    <div class="header_right">
      <!-- 添加具名插槽 -->
      <slot name="headerContent">
        <div
          v-if="user_type === 2 || user_type === 4"
          class="manage_router"
          @click="gotoAppPath"
        >
          <i class="el-icon-setting"></i>
          应用门户
        </div>
      </slot>
      <el-divider class="header_divider" direction="vertical"></el-divider>
      <el-dropdown @command="handleDropdownCommand">
        <span class="el-dropdown-link">
          <img src="@/assets/images/header/avatar.png" alt="头像" />
          <div>{{ display_name }}</div>
          <i class="el-icon-caret-bottom"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout" icon="el-icon-circle-plus-outline">
            退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    user_name: localStorage.getItem('user_name') || '',
    showUserMenu: false
  }),
  computed: {
    ...mapGetters(['user_type']),
    // 自定义logo
    logSrc() {
      const admin_enterprise_detail = JSON.parse(
        localStorage.getItem('admin_enterprise_detail') || null
      );
      if (admin_enterprise_detail) {
        const logo = admin_enterprise_detail.enterprise_profile;
        if (logo) return logo;
      }
      return require('@/assets/common-admin-logo.png');
    },
    display_name() {
      let admin_user_info = JSON.parse(
        localStorage.getItem('admin_user_info') || null
      );
      if (admin_user_info) {
        const display_name = admin_user_info.display_name;
        return display_name;
      }
      return '';
    },

    hasPresonalLogo() {
      let admin_enterprise_detail = JSON.parse(
        localStorage.getItem('admin_enterprise_detail')
      );
      if (admin_enterprise_detail) {
        const logo = admin_enterprise_detail.enterprise_profile;
        if (logo) {
          return true;
        }
      }
      // 如果没有enterprise_profile或logoUrl
      return false;
    },

    show_admin_platform() {
      if (this.user_type === 2 || this.user_type === 4) {
        const { fullPath } = this.$route;
        if (fullPath !== '/portal') {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    isEllipsisActive(elem) {
      return elem.offsetWidth < elem.scrollWidth;
    },

    clickShowUserPreference() {
      this.showUserMenu = false;
      this.showUserPreference();
    },

    goSetLicense() {
      const that = this;
      that.$router.push('/manage/license');
      that.hideUserPreference();
    },
    goApplyLicense() {
      const that = this;
      that.$router.push('/manage/license-apply');
      that.hideUserPreference();
    },

    logout() {
      Cookies.remove('teamsun_token');
      this.$router.push('/login');
    },
    handleDropdownCommand(command) {
      const commandMap = {
        profile: () => this.clickShowUserPreference(), // 个人信息
        setLicense: () => this.goSetLicense(), // 设置license
        applyLicense: () => this.goApplyLicense(), // 申请license
        logout: () => this.logout() // 登出
      };

      const handler = commandMap[command];
      if (handler && typeof handler === 'function') {
        handler();
      }
    },
    gotoAppPath() {
      this.$router.push('/portal');
    }
  }
};
</script>

<style lang="scss" scoped>
$page-header-color: #ffffff;

$color-select: #f4f4f4;
$color-select-hover: #9393a2;

$page-menu-width: 180px;
$page-header-height: 50px;

$page-menu-color: #fff;

@mixin header-border-bottom() {
  box-shadow: var(--shadow-primary);
  border-bottom: 1px solid var(--color-divider-normal);
}

#page-header {
  width: 100%;
  height: $page-header-height;
  background: $page-header-color;
  padding: 0px 16px;
  box-sizing: border-box;
  @include header-border-bottom();

  color: #000000;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .app_logo {
    height: $page-header-height - 2px;
    background-color: $page-menu-color;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      max-height: 40px;
      width: auto;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      margin-left: 16px;
      font-family: 'PingFang SC';
    }
  }

  .header_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header_divider {
    height: 16px;
    background-color: #e0e0e0;
  }

  #header_desc {
    height: 32px;
    width: 32px;
    background-color: #f2f3f5;
    border-radius: 50%;
    margin: 0px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #eeeeee;
    font-size: 18px;
  }
}

/* 菜单标题文字颜色*/
.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
}

.manage_router {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-right: 16px;
}
</style>
