import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// vue2
import WujieVue from 'wujie-vue2';
// 按需引入element
import '@/element/index';
import '@/assets/styles/index.scss'; // global css
import i18n from '@/lang'; // 引入i18n实例
import '@/router/permission';
import '@/utils/request';
import TsIcon from '@/components/ts-icon';

Vue.use(WujieVue);

/* svg 图标展示组件 */
Vue.component('TsIcon', TsIcon);
let importAll = requireContext => requireContext.keys().forEach(requireContext);
try {
  importAll(require.context('./assets/svg-icons', true, /.svg$/));
} catch (error) {
  console.log(error);
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
