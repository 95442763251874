<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="icon" />
  </svg>
</template>

<script>
export default {
  name: 'TsIcon',
  props: {
    iconName: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon() {
      return `#${this.iconName}`;
    },
    svgClass() {
      if (this.className) {
        return `ts_icon ts_icon_${this.iconName} ` + this.className;
      } else {
        return `ts_icon ts_icon_${this.iconName}`;
      }
    }
  }
};
</script>

<style lang="scss">
.ts_icon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
}
</style>
