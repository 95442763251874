<template>
  <div :class="classObj" class="ts_container">
    <div class="ts_container_header">
      <TSHeader></TSHeader>
    </div>
    <div class="ts_container_main">
      <div class="main_container">
        <ts-sidebar class="sidebar_container" />
        <TSMain />
      </div>
    </div>
  </div>
</template>

<script>
import TSHeader from './TSHeader.vue';
import TSMain from './TSMain.vue';
import TsSidebar from './side-bar/index.vue';
import Watermark from '@/utils/watermark';

export default {
  data() {
    return {};
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation
      };
    }
  },
  components: { TSHeader, TsSidebar, TSMain },
  mounted() {
    this.setPageWaterMark();
  },
  methods: {
    // 登录后设置水印
    setPageWaterMark() {
      const user_name = localStorage.getItem('user_name');
      const today = new Date();

      // 使用getFullYear(), getMonth() (注意月份是从0开始的，所以需要+1), getDate() 来获取年、月、日
      const year = today.getFullYear();
      let month = today.getMonth() + 1; // 注意月份是从0开始的，所以要+1
      let day = today.getDate();

      // 为了确保月份和日期始终是两位数，我们可以使用padStart()方法（在ES2017中引入）
      month = month.toString().padStart(2, '0');
      day = day.toString().padStart(2, '0');

      // 将它们组合成一个完整的日期字符串
      const todayString = `${year}-${month}-${day}`;
      // set('显示内容', '挂载到的dom')
      Watermark.set(
        [`${user_name} ${todayString}`],
        document.querySelector('.ts_container')
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.ts_container {
  position: relative;
  .ts_container_main {
    height: calc(100vh - 50px);
    overflow: auto;
    .main_container {
      height: 100%;
      min-height: 300px;
      transition: margin-left 0.28s;
      position: relative;
      display: flex;
    }
  }
}
</style>
