<template>
  <div
    class="toggle_sidebar"
    :class="{ closed: !sidebar.opened }"
    @click="toggleClick"
  >
    <svg
      :class="{ 'is-active': isActive }"
      class="toggle_sidebar_icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
    >
      <path
        d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"
      />
    </svg>
    <p v-show="sidebar.opened">收起导航</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ToggleSidebar',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['sidebar'])
  },
  methods: {
    toggleClick() {
      this.$emit('toggleClick');
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle_sidebar {
  display: flex;
  margin: 0 12px;
  height: 50px;
  min-height: 50px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-top: 1px solid var(--color-divider-normal);
  .toggle_sidebar_icon {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  p {
    cursor: pointer;
    max-width: 156px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
  .toggle_sidebar_icon.is-active {
    transform: rotate(180deg);
  }
}
.toggle_sidebar.closed {
  justify-content: center;
}
</style>
