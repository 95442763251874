<template>
  <div id="app" class="app_div">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {},
  methods: {
    createImgBase(options) {
      const { content, width, height } = options;
      const canvasDom = document.createElement('canvas');
      let ctx = canvasDom.getContext('2d');
      canvasDom.width = width;
      canvasDom.height = height;
      if (ctx) {
        // 设置画笔的方向
        ctx.rotate((-14 * Math.PI) / 90);
        // 设置水印样式
        ctx.fillStyle = 'rgba(100,100,100,0.15)';
        ctx.font = 'italic 14px Arial';
        // 渲染水印
        content.forEach((text, index) => {
          ctx.fillText(text, 10, 30 * (index + 1)); // 纵向拉开30的间距
        });
      }
      // document.body.appendChild(canvasDom);
      // 将canvas转为图片
      return canvasDom.toDataURL('image/png');
    },
    getWaterMark({
      content,
      className,
      canvasHeight = 300,
      canvasWidth = 300
    }) {
      // 监听
      this.listenerDOMChange(className);
      // 生成图片
      const data_url = this.createImgBase({
        content,
        width: canvasWidth,
        height: canvasHeight
      });
      // 通过设置伪元素样式，添加水印图片为背景图
      const defaultStyle = `
        .${className} {
          position: relative;
        }
        .${className}::after {
          content: "";
          background-image: url(${data_url});
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          pointer-events: none;
        }`;

      const styleDom = document.createElement('style');
      styleDom.innerHTML = defaultStyle;
      document.head.appendChild(styleDom);
    },
    /**
     * 添加mutationObserver监听节点的变化
     * @param className
     */
    listenerDOMChange(className) {
      // 获取要监听的节点
      const targetNode = document.querySelector(`.${className}`);
      // 创建监听器
      const observer = new MutationObserver(mutationList => {
        // 遍历变化记录
        for (let mutationRecord of mutationList) {
          // 如果目标节点的class属性发生变化，判断是不是类名被删了，是的话把类名加回去
          if (mutationRecord.attributeName === 'class') {
            if (!Array.from(targetNode.classList).includes(className)) {
              targetNode.classList.add(className);
            }
          }
        }
      });
      // 启动监听
      observer.observe(targetNode, {
        attributes: true
      });
    }
  }
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  width: 100%;
  #app {
    height: 100%;
    width: 100%;
  }
}
</style>
